import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';

const Login = lazy(() => import('pages/Auth/Login'));
const VerificationCode = lazy(() => import('pages/Auth/VerificationCode'));
const Register = lazy(() => import('pages/Auth/Register'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const UpdatePassword = lazy(() => import('pages/Auth/UpdatePassword'));
const Permission = lazy(() => import('pages/User/NotFound'));
const NotFound = lazy(() => import('components/NotFound'));

export default function AppWrapper() {
  return (
    <div className="root-wrapper">
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/admin/login" exact component={Login} />
        <Route path="/verify-code/2auth" exact component={VerificationCode} />
        <Route path="/register" exact component={Register} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/verify-code/forgot-password" exact component={VerificationCode} />
        <Route path="/update-password" exact component={UpdatePassword} />
        <Route path="/permission-denined" exact component={Permission} />
        <Route path="/404" exact component={NotFound} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
