import { Dropdown, Menu, Popover } from 'antd';
import { logout } from 'api/authentication';
import IconPricing from 'assets/images/dollar-square.svg';
import IconDropDown from 'assets/images/icon-dropdown.svg';
import IconMenu from 'assets/images/icon_menu.svg';
import IconMenuClose from 'assets/images/icon_menu_close.svg';
import IconNoti from 'assets/images/noti.svg';
import Logo from 'assets/images/logo.svg';
import NoNoti from 'assets/images/notification.svg';
import avatarImg from 'assets/images/profile-circle.svg';
import IconPoint from 'assets/images/wallet-money.svg';
import IconLogout from 'assets/images/logout-white.svg';
import IconMypage from 'assets/images/profile.svg';
import Cookies from 'js-cookie';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import dayjs from 'dayjs';

import { ROLE_ADMIN, ROLE_SELLER, ROLE_USER } from 'util/constant';
import { useGetListNotification, useReadAllListNotification, useReadNotification } from 'hooks/user/useTrendingSearch';
import { DATEFORMAT2 } from 'common';
import CustomRenderEmpty from 'components/CustomRenderEmpty';
import { STAFF_ROLE } from '../../common/index';

interface Iprops {
  menu: any;
  infoUser: any;
}

export default function PageHeader({ menu: menuList, infoUser, className }: any) {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [visiableMenu, setVisiableMenu] = useState(false);
  const [notiQuantity] = useState<number>(0);
  const [noti, setNoti] = useState<any>();
  const [isReadAllNoti, setIsReadAllNoti] = useState(false);

  const { t } = useTranslation();
  const menuRef: any = useRef(null);
  const [info, setInfo] = useState<any>();
  const isAuthenticated = !!Cookies.get('token') || !!Cookies.get('refreshToken');

  const { data: listNotification } = useGetListNotification(isAuthenticated);
  const { isSuccess: isReadNotiSuccess } = useReadNotification(noti?.id);
  const { isSuccess: isReadAllSuccess } = useReadAllListNotification(isReadAllNoti);

  const isListVideoPage = history.location.pathname === '/videos';

  useEffect(() => {
    infoUser && setInfo(infoUser);
  }, [infoUser]);
  const handleLogout = async () => {
    const data = await logout();
    if (data.success) {
      let recentVideo: any = localStorage.getItem('recentVideo');
      Cookies.remove('roleUser');
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      Cookies.remove('tempData');
      localStorage.clear();
      localStorage.setItem('recentVideo', recentVideo);
      setVisiableMenu(false);
      if (history.location.pathname?.includes('admin')) history.push('/admin');
      else history.push('/');
    }
  };
  const handleClickMyPage = () => {
    setVisiableMenu(false);
    history.location.pathname === '/my-page' ? window.location.reload() : history.push('/my-page');
  };
  const handleByPersonalPlan = () => {
    history.push('/personal-plan');
  };
  const handleByCompanyPlan = () => {
    history.push('/company-plan');
  };
  const handleByPoint = () => {
    history.push('/by-point');
  };

  const { SubMenu } = Menu;

  const pathNameArr = history.location.pathname?.split('/');
  const pathLink = history.location.pathname?.includes('admin')
    ? `/${pathNameArr[1]}/${pathNameArr[2]}}`
    : `/${pathNameArr[1]}`;
  const menuProfile = (
    <Menu
      items={
        history.location.pathname?.includes('admin') || history.location.pathname?.includes('seller')
          ? [
              {
                label: (
                  <span onClick={handleLogout}>
                    <img src={IconLogout} alt="logout" />
                    {t('pageHeader.logOut')}
                  </span>
                ),
                key: 0,
              },
            ]
          : [
              {
                label: (
                  <span onClick={handleClickMyPage}>
                    <img src={IconMypage} alt="logout" />
                    {t('pageHeader.myPage')}
                  </span>
                ),
                key: 0,
              },
              {
                label: (
                  <span onClick={handleLogout}>
                    <img src={IconLogout} alt="logout" />
                    {t('pageHeader.logOut')}
                  </span>
                ),
                key: 1,
              },
            ]
      }
    />
  );
  const menuGuest = (
    <Menu items={[{ label: <span onClick={() => history.push('/login')}>{t('pageHeader.logIn')}</span>, key: 0 }]} />
  );
  const menuPlan = (
    <Menu
      items={[
        { label: <span onClick={handleByPersonalPlan}>{t('pageHeader.personalPlan')}</span>, key: 0 },
        {
          label: <span onClick={handleByCompanyPlan}>{t('pageHeader.companyPlan')}</span>,
          key: 1,
        },
      ]}
    />
  );

  const checkUrlUser: boolean =
    history.location.pathname.includes('admin') || history.location.pathname.includes('seller');

  const hrefLogo = () => {
    switch (info?.role) {
      case ROLE_USER:
        return '/';
      case ROLE_SELLER:
        return '/seller';
      case ROLE_ADMIN:
      case STAFF_ROLE[1].en:
      case STAFF_ROLE[2].en:
      case STAFF_ROLE[3].en:
      case STAFF_ROLE[4].en:
      case STAFF_ROLE[5].en:
      case STAFF_ROLE[6].en:
        return '/admin';
      default:
        return '/';
    }
  };

  useEffect(() => {
    if (isReadNotiSuccess && noti?.link) {
      window.location.href = noti?.link;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadNotiSuccess]);

  const handleClickNoti = (item: any) => {
    setNoti(item);
  };

  const handleClickReadAll = () => {
    setIsReadAllNoti(true);
  };

  return (
    <React.Fragment>
      <div
        className={`${styles.headerWrapper} ${isListVideoPage && 'z-index-9999'} ${
          styles.menuPc
        } headerContainer ${className} ${!checkUrlUser && styles.headerSecond} `}
      >
        <div className={styles.menuLeft}>
          <a className={styles.branch} href={hrefLogo()}>
            <img src={Logo} alt="" />
          </a>
          <Menu mode="horizontal" overflowedIndicator={false}>
            {menuList.map((menu: any) => {
              if (menu.subMenu) {
                const linkSub = menu?.subMenu?.map((val: any) => val.link);
                return (
                  <SubMenu
                    className={`${linkSub.includes(pathLink) ? 'active' : ''}`}
                    key={`setting:${menu.key}`}
                    title={menu.text}
                    icon={<img src={menu.icon} alt="" />}
                  >
                    {menu.subMenu.map((subMenu: any) => (
                      <Menu.Item
                        className={`${subMenu.link === pathLink ? 'active' : ''}`}
                        key={`setting:${menu.key}${subMenu.key}`}
                        onClick={() =>
                          history.location.pathname === subMenu.link
                            ? window.location.reload()
                            : history.push(subMenu.link)
                        }
                      >
                        {subMenu.menu}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }
              return (
                <Menu.Item
                  className={`${pathLink.includes(menu.link) ? 'active' : ''}`}
                  key={`setting:${menu.key}${menu.key}`}
                  icon={menu.icon && <img src={menu.icon} alt="" />}
                  onClick={() =>
                    history.location.pathname === menu.link || history.location.pathname === menu.url
                      ? window.location.reload()
                      : history.push(menu.link || menu.url)
                  }
                >
                  {menu.text}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
        <div className={styles.menuRight}>
          <div className={`${styles.menuItem} ${styles.icon}`}>
            <div className={`${styles.boxNoti}`}>
              <Popover
                placement="bottom"
                trigger="click"
                getPopupContainer={(trigger: any) => trigger.parentNode}
                title={
                  <div className={styles.noti}>
                    <div className={styles.readAll} onClick={() => handleClickReadAll()}>
                      {t('common.readAll')}
                    </div>
                    <div className={styles.listNoti}>
                      <div className={styles.contentList}>
                        {isAuthenticated && listNotification?.length > 0 ? (
                          listNotification?.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`${styles.itemNoti} ${
                                (item?.status === 'New' || !!item?.link) && 'cursor-pointer'
                              }`}
                              onClick={() => handleClickNoti(item)}
                            >
                              <img
                                src={IconNoti}
                                alt="noti"
                                className={item?.status !== 'New' ? styles.hiddenIcon : ''}
                              />
                              <div className={styles.content}>
                                <div className={`flex-between ${styles.title}`}>
                                  <span>{item?.title}</span>
                                  <span className={styles.dateTime}>
                                    {item?.createdAt ? dayjs(item?.createdAt)?.format(DATEFORMAT2) : ''}
                                  </span>
                                </div>
                                <div className={styles.message}>{item?.message}</div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <CustomRenderEmpty />
                        )}
                      </div>
                    </div>
                  </div>
                }
              >
                <div className={styles.iconNoti}>
                  <img className="pointer" src={NoNoti} alt="" />
                  <img
                    src={IconNoti}
                    alt="noti"
                    className={`newNoti ${
                      (!isAuthenticated ||
                        listNotification?.length < 0 ||
                        !listNotification?.some((item: any) => item?.status === 'New')) &&
                      styles.hiddenIcon
                    }`}
                  />
                </div>
              </Popover>
              {notiQuantity > 0 && <div className={styles.quantity}>{1}</div>}
            </div>
          </div>
          {!checkUrlUser && (
            <>
              <div className={`${styles.menuItem} ${styles.textIcon}`} onClick={handleByPoint}>
                <img className="pointer" src={IconPoint} alt="" />
                <span>{t('menuTop.point')}</span>
              </div>
              <div className={`${styles.menuItem} ${styles.textIcon}`}>
                <Dropdown
                  overlay={menuPlan}
                  trigger={['click']}
                  overlayClassName={styles.dropMenu}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <div>
                    <img className="pointer" src={IconPricing} alt="" />
                    <span>{t('menuTop.pricing')}</span>
                  </div>
                </Dropdown>
              </div>
            </>
          )}
          {isAuthenticated ? (
            <Dropdown
              overlay={isAuthenticated ? menuProfile : menuGuest}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              trigger={['click']}
              placement="bottom"
              overlayClassName={styles.dropMenu}
            >
              <div>
                <img
                  className={`${!info?.avatar ? styles.default : styles.avatar}`}
                  src={info?.avatar ? info.avatar : avatarImg}
                  alt=""
                />
                <span>{isAuthenticated && info ? `${info?.firstName}${info?.lastName}` : t('common.login')}</span>
                <img className={styles.dropdown} src={IconDropDown} alt="" />
              </div>
            </Dropdown>
          ) : (
            <div onClick={() => history.push('/login')} className={styles.profile}>
              <img className={`${styles.default}`} src={avatarImg} alt="" />
              <span>{t('common.login')}</span>
            </div>
          )}
        </div>
      </div>
      <div className={isOpen ? styles.layoutDisplay : styles.layoutDisplayNone}></div>

      <div className={`${styles.notifyContent} ${isOpen ? styles.right0 : styles.rightSup200}`}>
        <div className={styles.titleHeader}>
          <img src={IconMenuClose} alt="" onClick={() => setOpen(false)} />
          <h2>{t('notification')}</h2>
        </div>
        <div className={styles.contentContainer} ref={menuRef}>
          {/* {loadingInfi ? (
            <Loading />
          ) : listByIdInfi?.pages[0]?.data?.length === 0 ? (
           */}{' '}
          <React.Fragment>
            <h2 className={styles.empyText}>{t('emptyText')}</h2>
          </React.Fragment>
          {/*) : ( 
            {/*  listByIdInfi?.pages?.map((page: any, ind: number) => (
              <React.Fragment key={ind}>
                {page?.data.map((item: any, i: number) => (
                  <div className={styles.warpperitem} key={i}>
                    <div
                      onClick={() => handleChangeLink(item?.link, item?.id)}
                      className={styles.itemContainer}
                      key={i}
                    >
                      <div className={styles.content}>
                        <div className={styles.timeC}>{dayjs(item?.created_at).format(TIMEFORMATNOTI)}</div>
                        <div>
                          <p className={styles.des}>{item?.content}</p>
                        </div>
                      </div>

                      <div className={styles.isRead}>
                        {!Boolean(item?.status) && <span className={styles.dot}></span>}
                      </div>
                    </div>
                    {!Boolean(item?.status) && (
                      <div className={styles.divBtn}>
                        <Button type="text" onClick={() => handleChangeStatus(item?.id)}>
                          {t('read')}
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </React.Fragment>
            ))
           )}*/}
        </div>
      </div>
      <div className={`${styles.headerWrapper} ${styles.menuSp} ${className} ${isListVideoPage && 'z-index-1000'}`}>
        <div className={styles.menuLeft}>
          <Popover
            overlayClassName={styles.popOVerlay}
            content={
              <div className={styles.popover}>
                <div className={styles.firstItem}>
                  {isAuthenticated ? (
                    <>
                      <img
                        className={`${styles.avatar} ${!info?.avatar && styles.default}`}
                        src={info?.avatar ? info.avatar : avatarImg}
                        alt=""
                      />
                      <span>{`${info?.firstName}${info?.lastName}`}</span>
                    </>
                  ) : (
                    <span onClick={() => history.push('/login')}>{t('common.login')}</span>
                  )}
                </div>

                <Menu mode="inline">
                  {menuList.map((menu: any) => {
                    if (menu.subMenu) {
                      const linkSub = menu?.subMenu?.map((val: any) => val.link);
                      return (
                        <SubMenu
                          className={`${linkSub.includes(pathLink) ? 'active' : ''}`}
                          key={`setting:${menu.key}`}
                          title={menu.text}
                          icon={<img src={menu.icon} alt="" />}
                        >
                          {menu.subMenu.map((subMenu: any) => {
                            return (
                              <Menu.Item
                                className={`${subMenu.link === pathLink ? 'active' : ''}`}
                                key={`setting:${menu.key}${subMenu.key}`}
                                onClick={() => {
                                  setVisiableMenu(false);
                                  history.location.pathname === subMenu.link
                                    ? window.location.reload()
                                    : history.push(subMenu.link);
                                }}
                              >
                                {subMenu.menu}
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }

                    return (
                      <Menu.Item
                        className={`${pathLink.includes(menu.link) ? 'active' : ''}`}
                        key={`setting:${menu.key}${menu.key}`}
                        icon={<img src={menu.icon} alt="" />}
                        onClick={() => {
                          setVisiableMenu(false);
                          history.location.pathname === menu.link ? window.location.reload() : history.push(menu.link);
                        }}
                      >
                        {menu.text}
                      </Menu.Item>
                    );
                  })}
                </Menu>
                {isAuthenticated && (
                  <div className={styles.afterLogin}>
                    {!(
                      history.location.pathname?.includes('admin') || history.location.pathname?.includes('seller')
                    ) && (
                      <div
                        className={`${styles.itemList} ${pathLink.includes('/my-page') ? styles.active : ''}`}
                        onClick={handleClickMyPage}
                      >
                        {' '}
                        <span>{t('pageHeader.myPage')}</span>{' '}
                      </div>
                    )}

                    <div className={styles.itemList}>
                      <span onClick={handleLogout}>{t('pageHeader.logOut')}</span>
                    </div>
                  </div>
                )}
              </div>
            }
            title={null}
            trigger="click"
            visible={visiableMenu}
            placement="topRight"
            onVisibleChange={(e) => {
              setVisiableMenu(!visiableMenu);
            }}
          >
            <img className={styles.iconMenu} src={visiableMenu ? IconMenuClose : IconMenu} alt="" />
          </Popover>
          <a className={styles.branch} href={'/'}>
            <img src={Logo} alt="" />
          </a>
        </div>

        <div className={styles.menuRight}>
          <div className={`${styles.menuItem} ${styles.icon}`}>
            <div className={`${styles.boxNoti}`}>
              <Popover
                placement="bottom"
                trigger="click"
                getPopupContainer={(trigger: any) => trigger.parentNode}
                title={
                  <div className={styles.noti}>
                    <div className={styles.readAll} onClick={() => handleClickReadAll()}>
                      {t('common.readAll')}
                    </div>
                    <div className={styles.listNoti}>
                      <div className={styles.contentList}>
                        {isAuthenticated && listNotification?.length > 0 ? (
                          listNotification?.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`${styles.itemNoti} ${
                                (item?.status === 'New' || !!item?.link) && 'cursor-pointer'
                              }`}
                              onClick={() => handleClickNoti(item)}
                            >
                              <img
                                src={IconNoti}
                                alt="noti"
                                className={item?.status !== 'New' ? styles.hiddenIcon : ''}
                              />
                              <div className={styles.content}>
                                <div className={`flex-between ${styles.title}`}>
                                  <span>{item?.title}</span>
                                  <p className={styles.dateTime}>
                                    {item?.createdAt ? dayjs(item?.createdAt)?.format(DATEFORMAT2) : ''}
                                  </p>
                                </div>
                                <div className={styles.message}>{item?.message}</div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <CustomRenderEmpty />
                        )}
                      </div>
                    </div>
                  </div>
                }
              >
                <div className={styles.iconNoti}>
                  <img className="pointer" src={NoNoti} alt="" />
                  <img
                    src={IconNoti}
                    alt="noti"
                    className={`newNoti ${
                      (!isAuthenticated ||
                        listNotification?.length < 0 ||
                        !listNotification?.some((item: any) => item?.status === 'New')) &&
                      styles.hiddenIcon
                    }`}
                  />
                </div>
              </Popover>
              {notiQuantity > 0 && <div className={styles.quantity}>{1}</div>}
            </div>
          </div>
          {!checkUrlUser && (
            <>
              <div className={`${styles.menuItem} ${styles.textIcon}`} onClick={handleByPoint}>
                <img className="pointer" src={IconPoint} alt="" />
              </div>
              <div className={`${styles.menuItem} ${styles.textIcon}`}>
                <Dropdown
                  overlay={menuPlan}
                  trigger={['click']}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <div>
                    <img className="pointer" src={IconPricing} alt="" />
                  </div>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
