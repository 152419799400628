const REGEX_NUMBER_ONLY = /^[0-9]*[1-9][0-9]*$/;
const REGEX_NUMBER = /^[0-9\b]+$/;
const REGEX_NUMBER_2 = /^[0-9#+-]+$/;
const REGEX_URL = /((http(s?)):\/\/)([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{1,}/;
const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&()])[A-Za-z\d!@#$%&()]{8,24}$/;
const REGEX_NAME = /^[ぁ-んァ-ン一-龥a-zA-Z]+$/;
const REGEX_NUMBER_POS = /^\d*[1-9]\d*$/;
const REGEX_NUMBER_POS_V2 = /^[-|+|0-9]{0,1}[0-9]{1,}$/;
const REGEX_EMAIL = /^(?!\.)[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const REGEX_EMAIL_2 = /^(?!\.)[\w-.]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/;
const REGEX_POSTAL_CODE = /\d{3}-\d{4}$/;
const REGEX_NAME_KANA = /^[ァ-・ヽヾ゛゜ー]/;
const REGEX_NAME_KANA_2 = /^([ァ-ン]|ー)+$/;
const REGEXT_NUMBER = /\B(?=(\d{3})+(?!\d))/g;
const REGEX_IMAGE = /^image\//;
const REPLATE_HTML_TAG = /<\s*[^>]*>/gi;
const OTP_CHANGE_PASSWORD = 'forgot-password';
const OTP_CHANGE_EMAIL = 'change-email';
const OTP_2AUTH = '2auth';
const TERM_OTP = 120;
const TERM_OTP_CHANGE_EMAIL = 60;
const ROLE_USER = 'User';
const ROLE_SELLER = 'Creator';
const ROLE_ADMIN = 'Admin';
const ROLE_STAFF = 'Staff';
const LICENSE = 'license';
const RESOLUTION = 'resolution';
const DURATION = 'duration';
const SHOTANGLE = 'shot-angle';
const MASTER_TYPE = 'master'
const CATEGORY = 'category';
const TAGS = 'tags';
const SITUATION = 'situation';
const MODEL = 'model';
const FOOTAGECODE = 'footage-code';
const PUBLIC_DATE = 'public-date';
const FPS = 'fps';
const MAX_LIST_VIDEO_COMPARE = 2;
const TOKEN_TIME = 1 / 24; // 1 hour = 3600
const REFRESH_TOKEN = ((1 / 24) * 2800) / 3600; // 2800
const PERSONAL_TYPE = 'Personal';
const COMPANY_TYPE = 'Company';
const SELLING_PACKPOINT = 'Selling';
const CREATED_FROM = 'from';
const CREATED_TO = 'to';
const STATUS = 'status';
const SEARCH = 'search';
const PACKAGE = 'package';
const POINT = 'point';
const ENTERPRISE = 'enterprise';
const CAMPAIGN = 'campaign';
const NEWS = 'news';
const REGEX_NUMBER_PRICE = /^[0-9 .]*$/;
const REGEX_NUMBER_NUMBER_LIMIT = /^\d*[0-9]\d*$/;
const VIDEO_SALE = 'SALE';
const PRICE_PER_DOWNLOAD = 'PRICE_PER_DOWNLOAD';
const SALES_COMMISSION = 'SALES_COMMISSION';
const SALES_TAX = 'SALES_TAX';
const VISA_MASTER = 'VISA_MASTER';
const JCB_AMEX_DINERS = 'JCB_AMEX_DINERS';
const PAYPAL_PAYMENT = 'PAYPAL_PAYMENT';
const PROCESSING_FEE = 'PROCESSING_FEE';
const STATUS_BLOCK = 'Block';
const ALL = 'All';
const PLAN = 'PLAN';
const REGEX_TAG = /<[^>]+>/g;
const PURCHASE_HISTORY = 'purchase-history';
const PAYMENT_HISTORY = 'payment-history';
const SUBSCRIPTION = 'subscription';
const CHECK_VIDEO = 1.5;
const MAX_SIZE_VIDEO = 30;
const VIDEO_MP4 = 'video/mp4';
const URL_REGEX =
  // eslint-disable-next-line
  /((https?:\/\/)?(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export {
  REGEX_NUMBER_ONLY,
  REGEX_NUMBER,
  REGEX_NUMBER_2,
  REGEX_URL,
  REGEX_PASSWORD,
  REGEX_NUMBER_POS,
  REGEX_NUMBER_POS_V2,
  REGEX_EMAIL,
  REGEX_EMAIL_2,
  OTP_CHANGE_PASSWORD,
  OTP_CHANGE_EMAIL,
  OTP_2AUTH,
  TERM_OTP,
  REGEX_NAME,
  ROLE_USER,
  ROLE_SELLER,
  ROLE_ADMIN,
  REGEX_POSTAL_CODE,
  REGEX_NAME_KANA,
  REGEX_NAME_KANA_2,
  LICENSE,
  RESOLUTION,
  DURATION,
  SHOTANGLE,
  CATEGORY,
  FPS,
  TAGS,
  SITUATION,
  MODEL,
  PUBLIC_DATE,
  MAX_LIST_VIDEO_COMPARE,
  TOKEN_TIME,
  REFRESH_TOKEN,
  ROLE_STAFF,
  REGEXT_NUMBER,
  TERM_OTP_CHANGE_EMAIL,
  PERSONAL_TYPE,
  COMPANY_TYPE,
  SELLING_PACKPOINT,
  CREATED_FROM,
  CREATED_TO,
  STATUS,
  SEARCH,
  PACKAGE,
  POINT,
  CAMPAIGN,
  NEWS,
  REGEX_NUMBER_PRICE,
  REGEX_NUMBER_NUMBER_LIMIT,
  VIDEO_SALE,
  FOOTAGECODE,
  PRICE_PER_DOWNLOAD,
  SALES_COMMISSION,
  SALES_TAX,
  VISA_MASTER,
  JCB_AMEX_DINERS,
  PAYPAL_PAYMENT,
  PROCESSING_FEE,
  STATUS_BLOCK,
  ALL,
  PLAN,
  REGEX_TAG,
  ENTERPRISE,
  REPLATE_HTML_TAG,
  PURCHASE_HISTORY,
  PAYMENT_HISTORY,
  SUBSCRIPTION,
  REGEX_IMAGE,
  CHECK_VIDEO,
  MAX_SIZE_VIDEO,
  VIDEO_MP4,
  URL_REGEX,
  MASTER_TYPE
};
